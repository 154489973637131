import { computed } from "vue";
import { useQuery } from "@tanstack/vue-query";
import { getTranslationsData } from "../api";

export function useCurrentTranslations() {
  const translations = useQuery({
    queryKey: ["translations"],
    queryFn: () => getTranslationsData(),
  });

  return computed(() => translations.data.value?.data?.results || []);
}
