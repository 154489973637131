<script setup lang="ts">
import { useI18n } from "@/i18n";

const { t } = useI18n();
</script>

<template>
  <main class="fr-p-2v container">
    <div class="fr-container fr-container--fluid fr-container-md">
      <div class="fr-grid-row fr-grid-row--center">
        <div class="fr-modal__content">
          <h1
            id="fr-modal-title-modal-unsub"
            v-sanitize="t('popin.unsubscribed')"
            class="fr-modal__title"
          />
          <p>
            {{ t('popin.unsubscribed.subtitle') }}
            <a
              href="/"
            >{{ t('popin.unsubscribed.link') }}</a>
          </p>
          <p v-sanitize="t('popin.unsubscribed.redirected')" />
        </div>
        <div class="fr-modal__footer fr-pt-0">
          <div class="fr-fieldset__element fr-mx-0 fr-p-0">
            <ul class="fr-btns-group">
              <li>
                <a
                  class="fr-mt-2v fr-mx-0v fr-btn fr-btn--icon-left"
                  href="/"
                >
                  <span class="fr-icon-arrow-left-s-line" />
                  {{ t("popin.back") }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
.container {
  max-width: 597px;
  margin-left: auto;
  margin-right: auto;
}
</style>
