<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { onKeyStroke } from "@vueuse/core";
import HomeView from "./views/HomeView.vue";
import ModalSucess from "@/components/SuccessModal.vue";
import ModalUnsub from "@/components/UnsubModal.vue";
import UnsubscribedEmailRedirectView from "@/views/UnsubscribedEmailRedirectView.vue";

const isSuccesModalOpen = ref(false);
const isUnsubModalOpen = ref(false);

onKeyStroke("Escape", () => {
  isSuccesModalOpen.value = false;
  isUnsubModalOpen.value = false;
});

const isUnsubscribedPaged = computed(() =>
  window.location.href.includes("/unsubscribed"),
);
const isProdEnv = computed(() => window.location.hostname.includes("edugouv.1mapp6.com"));
const isInIframe = computed(
  () => window.top && window.top.location.hostname.includes("gouv.fr"),
);
const view = computed(() =>
  isUnsubscribedPaged.value ? UnsubscribedEmailRedirectView : HomeView,
);

onMounted(() => {
  if (!isUnsubscribedPaged.value && isProdEnv.value && !isInIframe.value) {
    window.location.assign(
      "https://www.devenirenseignant.gouv.fr/abonnez-vous-notre-lettre-d-information-1244",
    );
  }
});
</script>

<template>
  <component
    :is="view"
    v-model:is-succes-modal-open="isSuccesModalOpen"
    v-model:is-unsub-modal-open="isUnsubModalOpen"
  />
  <ModalSucess v-model:is-succes-modal-open="isSuccesModalOpen" />
  <ModalUnsub v-model:is-unsub-modal-open="isUnsubModalOpen" />
</template>
