import "./index.css";

import { type Directive, createApp } from "vue";
import createSanitize from "@guild-frontend/vue-sanitize";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { iframeResizerContentWindow } from "iframe-resizer";
import { initVeeValidate } from "./vee-validate";
import { i18n } from "./i18n";

import App from "./App.vue";

import "@gouvfr/dsfr/dist/dsfr.min.css";
import "@gouvfr/dsfr/dist/utility/utility.min.css";

// form imports
import "@gouvfr/dsfr/dist/core/core.min.css";
import "@gouvfr/dsfr/dist/component/form/form.min.css";
import "@gouvfr/dsfr/dist/component/button/button.min.css";
import "@gouvfr/dsfr/dist/component/input/input-base/input-base.min.css";

// eslint-disable-next-line no-unused-expressions
iframeResizerContentWindow;

initVeeValidate();
const app = createApp(App);

app.use(VueQueryPlugin).directive("sanitize", createSanitize() as Directive<HTMLElement, string>).use(i18n).mount("#app");
