import { computed, watch } from "vue";
import { useI18n as _useI18n, createI18n } from "vue-i18n";

import { useCurrentTranslations } from "@/composables/useCurrentTranslations";

/**
 * Escape `@` because Vue uses it as a special character for events like `@click`.
 */
const sanitize = (text: string) => text.replace(/@/g, "{'@'}");

export const i18n = createI18n({
  locale: "fr",
  fallbackLocale: "default",
  legacy: false,
  warnHtmlMessage: false,
  missingWarn: false,
});

export function useI18n() {
  const { setLocaleMessage, t } = _useI18n();

  const translations = useCurrentTranslations();

  const messages = computed(() => {
    return translations.value.reduce<{ [key: string]: string }>(
      (result, item) => ({
        ...result,
        [item.key]: sanitize(item?.value || ""),
      }),
      {},
    );
  });

  watch(
    messages,
    () => {
      setLocaleMessage("fr", messages.value);
    },
    { immediate: true },
  );

  return { t };
}
