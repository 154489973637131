<script setup lang="ts">
import { useField, useForm } from "vee-validate";
import { ref, watch } from "vue";
import { useI18n } from "../i18n";
import { postUnsubscribe } from "@/api";

const { handleSubmit, values, resetForm } = useForm();
const { t } = useI18n();

const isUnsubModalOpen = defineModel<boolean>("isUnsubModalOpen");
const isNoUnsubscribed = ref(true);
const isSubmitting = ref(false);
const email = useField("email", "required|email");

watch(isUnsubModalOpen, () => {
  if (!isUnsubModalOpen.value)
    isNoUnsubscribed.value = true;
});

const onSubmit = handleSubmit(() => {
  if (isSubmitting.value)
    return;

  isSubmitting.value = true;

  postUnsubscribe({ email: values.email }).then((res) => {
    if (res.response.status === 201) {
      resetForm();
      isNoUnsubscribed.value = false;
    }

    if (res.response.status === 404)
      email.setErrors(t("unsub.error.notfound"));

    isSubmitting.value = false;
  });
});
</script>

<template>
  <dialog
    id="fr-modal-unsub"
    aria-labelledby="fr-modal-title-modal-unsub"
    role="dialog"
    class="fr-modal"
    :class="{ 'fr-modal--opened': isUnsubModalOpen }"
    :open="isUnsubModalOpen"
  >
    <div class="fr-container fr-container--fluid fr-container-md">
      <div class="fr-grid-row fr-grid-row--center">
        <div class="fr-col-12 fr-col-md-4">
          <div class="fr-modal__body">
            <div class="fr-modal__header">
              <button
                class="fr-btn--close fr-btn"
                title="Fermer la fenêtre modale"
                aria-controls="fr-modal-unsub"
                @click="isUnsubModalOpen = false"
              >
                {{ t('popin.exit') }}
              </button>
            </div>
            <div class="fr-modal__content">
              <h1
                id="fr-modal-title-modal-unsub"
                v-sanitize="
                  isNoUnsubscribed ? t('popin.unsubscribe') : t('popin.unsubscribed')
                "
                class="fr-modal__title"
              />
            </div>
            <div v-if="isNoUnsubscribed" class="fr-modal__footer fr-pt-0">
              <form class="" @submit="onSubmit">
                <div
                  class="fr-input-group"
                  :class="{ 'fr-input-group--error': email.errorMessage.value }"
                >
                  <label
                    v-sanitize="t('register.form.emailLabel')"
                    class="fr-label"
                    for="email-unsub"
                  />
                  <input
                    id="email-unsub"
                    v-model="email.value.value"
                    class="fr-input"
                    name="email-unsub"
                    aria-labelledby="email-unsub-error-message"
                    :class="{ 'fr-input--error': email.errorMessage.value }"
                    autocomplete="on"
                    type="email"
                  >
                  <p
                    v-if="email.errorMessage.value"
                    id="email-unsub-error-message"
                    v-sanitize="email.errorMessage.value"
                    class="fr-error-text"
                  />
                </div>
                <div class="fr-fieldset__element fr-mx-0 fr-p-0">
                  <ul class="fr-btns-group">
                    <li>
                      <button
                        class="fr-mt-2v fr-mx-0v fr-btn fr-btn--icon-right"
                        aria-controls="fr-modal-success"
                        type="submit"
                      >
                        {{ t('popin.confirm2') }}
                        <span class="fr-icon-arrow-right-s-line" />
                      </button>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
            <div v-else class="fr-modal__footer fr-pt-0">
              <div class="fr-fieldset__element fr-mx-0 fr-p-0">
                <ul class="fr-btns-group">
                  <li>
                    <button
                      class="fr-mt-2v fr-mx-0v fr-btn fr-btn--icon-left"
                      aria-controls="fr-modal-success"
                      type="submit"
                      @click="isUnsubModalOpen = false"
                    >
                      <span class="fr-icon-arrow-left-s-line" />
                      {{ t("popin.back") }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dialog>
</template>

<style>
.text-align-center {
  text-align: center;
}
</style>
