import createClient from "openapi-fetch";
import type { components, paths } from "./types/api.interfaces.newsletter";

function getCsrfCookie() {
  const parts = document.cookie.split(";");
  const csrfCookie = parts.find(part => part.trim().startsWith("csrftoken="));

  if (!csrfCookie)
    throw new Error("CSRF cookie not found, make sure the cookie is set correctly without \"Secure\" and \"HttpOnly\"");

  return csrfCookie?.split("=")[1];
}

const client = createClient<paths>({ referrerPolicy: "origin-when-cross-origin", headers: {
  "Content-Type": "application/json",
} });

export function getTranslationsData() {
  return client.GET("/api/translations/");
}

export function postRegister(body: components["schemas"]["Register"]) {
  return client.POST("/api/register/", { body, headers: { "X-CSRFToken": getCsrfCookie() } });
}

export function postUnsubscribe(body: components["schemas"]["Unsubscribe"]) {
  return client.POST("/api/unsubscribe/", { body, headers: { "X-CSRFToken": getCsrfCookie() } });
}
