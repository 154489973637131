<script setup lang="ts">
import { useField, useForm } from "vee-validate";
import { ref } from "vue";
import { postRegister } from "../api";
import { useI18n } from "@/i18n";
import type { components } from "@/types/api.interfaces.newsletter";

const { t } = useI18n();

const { handleSubmit, validateField, setFieldError, resetForm } = useForm();
const lastname = useField<string>("firstname", "required|alpha");
const firstname = useField<string>("lastname", "required|alpha");
const email = useField<string>("email", "required|email");
const zipcode = useField<string>("zipcode", "required|digits:5");
const study = useField<components["schemas"]["StudyEnum"] | "">("study", "required", {
  initialValue: "",
});

const isSuccesModalOpen = defineModel("isSuccesModalOpen");
const isUnsubModalOpen = defineModel("isUnsubModalOpen");
const isSubmitting = ref(false);

const onSubmit = handleSubmit(() => {
  if (isSubmitting.value)
    return;

  isSubmitting.value = true;

  const formData: components["schemas"]["Register"] = {
    firstname: firstname.value.value,
    lastname: lastname.value.value,
    email: email.value.value,
    zip: zipcode.value.value,
    study: study.value.value,
  };

  postRegister(formData).then(async (res) => {
    if (res.response.status === 201) {
      resetForm();
      isSuccesModalOpen.value = true;
    }

    // Wait async email rules otherwise they will overide emailField.setErrors
    if (res.response.status !== 201)
      await validateField("email");

    if (res.response.status === 409)
      setFieldError("email", t("errors.email.alreadyRegistered"));
    else if (res.response.status === 400 && res.data?.email === "email_invalid_alias")
      setFieldError("email", t("errors.email.invalidAliasGmail"));
    else if (
      res.response.status === 400
      && res.data?.email === "email_invalid_host_alias_unallow"
    )
      setFieldError("email", t("errors.email.invalidAliasPlus"));

    isSubmitting.value = false;
  });
});
</script>

<template>
  <main>
    <form class="fr-p-2v form-container" @submit="onSubmit">
      <div
        class="fr-input-group"
        :class="{ 'fr-input-group--error': lastname.errorMessage.value }"
      >
        <label
          v-sanitize="t('register.form.lastnameLabel')"
          class="fr-label"
          for="input-family-name"
        />
        <input
          id="input-family-name"
          v-model="lastname.value.value"
          :class="{ 'fr-input--error': lastname.errorMessage.value }"
          class="fr-input"
          spellcheck="false"
          aria-describedby="input-family-name-message-error"
          autocomplete="family-name"
          name="input-family-name"
          type="text"
          :placeholder="t('register.form.lastnamePlaceholder')"
        >
        <p
          v-if="lastname.errorMessage.value"
          id="input-family-name-message-error"
          v-sanitize="lastname.errorMessage.value"
          class="fr-error-text"
        />
      </div>
      <div
        class="fr-input-group"
        :class="{ 'fr-input-group--error': firstname.errorMessage.value }"
      >
        <label
          v-sanitize="t('register.form.firstnameLabel')"
          class="fr-label"
          for="input-firstname"
        />
        <input
          id="input-firstname"
          v-model="firstname.value.value"
          class="fr-input"
          :class="{ 'fr-input--error': firstname.errorMessage.value }"
          spellcheck="false"
          autocomplete="given-name"
          aria-describedby="input-firstname-message-error"
          name="input-firstname"
          type="text"
          :placeholder="t('register.form.firstnamePlaceholder')"
        >
        <p
          v-if="firstname.errorMessage.value"
          id="input-firstname-message-error"
          v-sanitize="firstname.errorMessage.value"
          class="fr-error-text"
        />
      </div>

      <div
        class="fr-input-group"
        :class="{ 'fr-input-group--error': email.errorMessage.value }"
      >
        <label
          v-sanitize="t('register.form.emailLabel')"
          class="fr-label"
          for="input-email"
        >
          <span
            v-sanitize="t('form.email.advice')"
            class="fr-hint-text"
          /></label>
        <input
          id="input-email"
          v-model="email.value.value"
          :class="{ 'fr-input--error': email.errorMessage.value }"
          class="fr-input"
          name="input-email"
          aria-describedby="input-email-message-error"
          autocomplete="on"
          type="email"
          :placeholder="t('register.form.emailPlaceholder')"
        >
        <p
          v-if="email.errorMessage.value"
          id="input-email-message-error"
          v-sanitize="email.errorMessage.value"
          class="fr-error-text"
        />
      </div>
      <div
        class="fr-input-group"
        :class="{ 'fr-input-group--error': zipcode.errorMessage.value }"
      >
        <label
          v-sanitize="t('register.form.zipcodeLabel')"
          class="fr-label"
          for="input-code-postal"
        />
        <input
          id="input-code-postal"
          v-model="zipcode.value.value"
          :class="{ 'fr-input--error': zipcode.errorMessage.value }"
          class="fr-input"
          maxlength="5"
          aria-describedby="input-code-postal-message-error"
          type="text"
          name="input-code-postal"
          :placeholder="t('register.form.zipcodePlaceholder')"
        >
        <p
          v-if="zipcode.errorMessage.value"
          id="input-code-postal-message-error"
          v-sanitize="zipcode.errorMessage.value"
          class="fr-error-text"
        />
      </div>

      <div
        class="fr-select-group"
        :class="{ 'fr-select-group--error': study.errorMessage.value }"
      >
        <label
          v-sanitize="t('register.form.studyLabel')"
          class="fr-label"
          for="input-select"
        />
        <select
          id="input-select"
          v-model="study.value.value"
          class="fr-select"
          aria-describedby="input-select-message-error"
          name="input-select"
          :class="{ 'fr-select--error': study.errorMessage.value }"
        >
          <option
            value=""
            selected
            disabled
            hidden
          >
            {{ t('register.form.study.options0') }}
          </option>
          <option value="seconde">
            {{ t('register.form.study.options1') }}
          </option>
          <option value="premiere">
            {{ t('register.form.study.options2') }}
          </option>
          <option value="terminal">
            {{ t('register.form.study.options3') }}
          </option>
          <option value="l1">
            {{ t('register.form.study.options4') }}
          </option>
          <option value="l2">
            {{ t('register.form.study.options5') }}
          </option>
          <option value="l3">
            {{ t('register.form.study.options6') }}
          </option>
          <option value="m1">
            {{ t('register.form.study.options7') }}
          </option>
          <option value="m2">
            {{ t('register.form.study.options8') }}
          </option>
          <option value="reconversion">
            {{ t('register.form.study.options9') }}
          </option>
          <option value="other">
            {{ t('register.form.study.options10') }}
          </option>
        </select>
        <p
          v-if="study.errorMessage.value"
          id="input-select-message-error"
          v-sanitize="study.errorMessage.value"
          class="fr-error-text"
        />
      </div>
      <div class="fr-fieldset__element fr-mx-0 fr-p-0">
        <ul class="fr-btns-group">
          <li>
            <button
              class="fr-mt-2v fr-mx-0v fr-btn fr-btn--icon-right"
              aria-controls="fr-modal-success"
              type="submit"
            >
              {{ t("register.confirm") }}
              <span class="fr-icon-arrow-right-s-line" />
            </button>
          </li>
        </ul>
      </div>
    </form>
    <div class="fr-fieldset__element form-container">
      <ul class="fr-btns-group">
        <li>
          <button
            class="fr-mt-2v fr-mx-2v fr-btn--secondary fr-btn fr-btn--icon-right"
            aria-controls="fr-modal-unsub"
            type="button"
            @click="isUnsubModalOpen = true"
          >
            {{ t("register.signout") }}
            <span class="fr-icon-arrow-right-s-line" />
          </button>
        </li>
      </ul>
    </div>
  </main>
</template>

<style scoped>
.form-container {
  max-width: 597px;
  margin-left: auto;
  margin-right: auto;
}
</style>
