<script setup lang="ts">
import { useI18n } from "vue-i18n";

const isSuccesModalOpen = defineModel<boolean>("isSuccesModalOpen");
const { t } = useI18n();
</script>

<template>
  <dialog
    id="fr-modal-success"
    aria-labelledby="fr-modal-title-modal-success"
    role="dialog"
    class="fr-modal"
    :class="{ 'fr-modal--opened': isSuccesModalOpen }"
    :open="isSuccesModalOpen"
  >
    <div class="fr-container fr-container--fluid fr-container-md">
      <div class="fr-grid-row fr-grid-row--center">
        <div class="fr-col-12 fr-col-md-8 text-align-center">
          <div class="fr-modal__body">
            <div class="fr-modal__header">
              <button
                class="fr-btn--close fr-btn"
                title="Fermer la fenêtre modale"
                aria-controls="fr-modal-success"
                @click="isSuccesModalOpen = false"
              >
                {{ t('popin.exit') }}
              </button>
            </div>
            <div class="fr-modal__content">
              <h1 id="fr-modal-title-modal-success" class="fr-modal__title">
                {{ t('popin.success.title') }}
              </h1>
              <p>{{ t('popin.success.subtitle') }}</p>
            </div>
            <div class="fr-modal__footer">
              <div class="fr-btns-group fr-btns-group--inline-lg fr-btns-group--icon-right">
                <a
                  href="https://www.devenirenseignant.gouv.fr/"
                  rel="noopener"
                  target="_blank"
                  class="fr-btn fr-btn--icon-left text-align-center"
                >
                  {{ t('popin.success.cta') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dialog>
</template>

<style>
.text-align-center {
  text-align: center;
}
</style>
