import { configure, defineRule } from "vee-validate";
import { alpha, digits, email, max_value, min_value, required } from "@vee-validate/rules";
import { localize } from "@vee-validate/i18n";
import { computed, watch } from "vue";
import { i18n } from "./i18n";

const { t } = i18n.global;

export function initVeeValidate() {
  defineRule("required", required);
  defineRule("email", email);
  defineRule("min_value", min_value);
  defineRule("max_value", max_value);
  defineRule("digits", digits);
  defineRule("alpha", alpha);
  defineRule("max_value", max_value);

  const errorTraductions = computed(() => ({
    messages: {
      required: t("errors.required"),
      email: t("errors.email.invalid"),
      emailDomain: t("errors.email.unauthorized"),
      alpha: t("errors.invalid"),
      phoneNumber: t("errors.invalid"),
      min_value: t("errors.invalid"),
      max_value: t("errors.invalid"),
    },
    names: {
      firstname: t("register.form.firstnameLabel"),
      lastname: t("register.form.lastnameLabel"),
      email: t("register.form.emailLabel"),
      zipcode: t("register.form.zipcodeLabel"),
      study: t("register.form.studyLabel"),
    },
  }));

  watch(errorTraductions, () => {
    configure({
      generateMessage: localize("default", errorTraductions.value),
    });
  });
}
